.music-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}
.cover-art{
    margin-bottom: 15px;
    transition: transform 0.2s ease;
}
.section-header{
    margin-bottom: 15px;
}
.cover-art:hover{
    transform: scale(1.1)
}