.design-container {
    top: 0;
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding: 56px 50px 50px 50px;
    z-index: -1;
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: center;
}
.design-container h2 {
    width: 100%;
}

.design-card {
    width: 100%;
    margin: 10px 0;
    background: white;
    padding: 20px;
    border: 2px black solid;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    height: auto;
}
@media screen and (max-width: 768px) {
    .design-card {
        width: 100%;
    }
}

@media screen and (min-width: 769px) {
    .design-card {
        width: calc(50% - 20px);
    }
}
@media screen and (min-width: 915px) {
    .design-card {
        width: calc(33.33% - 20px);
    }
}
.design-card:hover {
    background: rgb(250, 250, 250);
}

.design-card > a {
    display: block;
}
.design-image {
    width: calc(75% - 20px);
    display: none;
}

.design-title-card {
    width: 100%;
}
.design-title-card > img {
    width: 100%;
}

.design-information {
    width: 100%;
}

@media screen and (max-width: 915px) {
    .design-text {
        width: 100%;
    }
    .design-image {
        width: 100%;
    }
}
.design-container > p {
    color: rgb(26, 26, 26);
    font-size: 1.375em;
    margin-top: 15px;
    overflow-wrap: break-word;
    width: 100%;
    text-align: left;
}

.design-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    row-gap: 20px;
    column-gap: 20px;
}
.website-image {
    width: 100%;
    min-width: 50%;
    margin: auto;
}

.caption {
    font-size: 90%;
}
