@media screen and (max-width: 720px) {
    video {
        display: none;
    }
}
video {
    object-fit: cover;
    width: 25%;
    height: 25%;
    position: absolute;
    top: calc(100vh - 25%);
    z-index: -2;
}
.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    z-index: -1;
    background: white;
    padding: 20px;
    border: 2px black solid;
    margin: 50px;
}

.hero-container > h1 {
    font-size: 2em;
    overflow-wrap: break-word;
}
