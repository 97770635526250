.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo-text{
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 36px;
  margin-left: 15px;
  color: black;
  display:inline;
}
.navbar-logo {
  text-decoration: none;
}
.navbar-link{
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 24px;
  margin-left: 15px;
  color: black;
}
@media screen and (max-width:600px) {
  .logo-container{
    display:inline-block;
    float: left;
    width: fit-content;

  }
  .links-container{
    display:inline-block;
    float: right;
    width:fit-content;

  }
  
}
@media screen and (min-width:601px) {
  .logo-container{
    display:block;
    float: left;

  }
  .links-container{
    display:inline-block;
    float: right;
    width:fit-content;

  }
}
*{
  font-family: Verdana, sans-serif;
}
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.auth-form {
  margin-top: 10px;
}

.loader {
  margin: 100px auto;
}

.user-details {
  display: flex;
  justify-content: space-between;
}
.user-actions {
  display: flex;
  flex-direction: column;
}

.account-card{
  width: 80%;
  margin: auto;
  background-color: transparent;
  box-shadow: none;
}
body{
  background-color: white;
}