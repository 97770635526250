form {
    display: flex;
    flex-direction: row;
    width: calc(100% - 60px);
    height: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
}
form > label:not([for="Content"]) {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
form > label[for="Content"] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
form > textarea {
    width: 100%;
    height: 100%;

}
#editor {
    width: 100%;
    height: 100%;
    min-height: 500px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    outline: none;
    background-color: white;
    cursor: text;
}