.about-container{
    height: fit-content;
    width: 100%;
    padding: 56px 50px;
    z-index: -1;
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}
.about-image{
    height: calc(95vh - 50px);
    display: inline-block;
}
.about-text-box{
    width: 40%;
    margin-left: 10%;
    display: inline-block;
    vertical-align: middle;
}
@media screen and (max-width: 768px) {
    .about-image{
        width:auto;
        
        max-height: 50vh;
        display:flex;
        margin:auto;
    }
    .about-text-box{
        width: 100%;
        margin-left: 0%;
        margin-top: 50px;
        display: inline-block;
        vertical-align: middle;
    }
    
}
.about-container > h1 {
    color: rgb(114, 114, 114);
    font-size: 3em;
    margin-top: 100px;
    max-width: 95vw;
    overflow-wrap: break-word;
}
.about-container > h2 {
    color: rgb(114, 114, 114);
    font-size: 2.5em;
    margin-top: 100px;
    max-width: 95vw;
    overflow-wrap: break-word;
}
.about-container > p {
    color: rgb(114, 114, 114);
    font-size: 2em;
    margin-top: 100px;
    max-width: 95vw;
    overflow-wrap: break-word;
}
.obEmail::after{
    content: '@';
}