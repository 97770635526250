.guessHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 15px;
}
.guess {
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.guessBox {
    display: block;
    width: 45px;
    height: 45px;
    text-align: center;
    background-color: gray;
    font-size: 30px;
    color: white;
    caret-color: transparent;
    transition: all 0.3s ease-in-out;
}

.correct {
    background-color: rgb(0, 92, 0) !important;
    color: white !important;
}
.incorrect {
    background-color: rgb(150, 0, 0) !important;
    color: white !important;
}
.close {
    background-color: rgb(150, 150, 0);
    color: white !important;
}

.activeGuess > .guessBox {
    background-color: transparent;
    color: black;
    border: 2px solid gray;
}
.guessBox:focus {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        border-color: transparent;
    }
    50% {
        border-color: gray;
    }
    100% {
        border-color: transparent;
    }
}
.numberHolder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    height: 100%;
    margin-top: 15px;
}
.numberKey {
    display: block;
    width: 45px;
    height: 45px;
    text-align: center;
    background-color: gray;
    font-size: 30px;
    color: white;
    caret-color: transparent;
    cursor: pointer;
}
.solve {
    display: block;
    font-size: 30px;
    height: 45px;
    text-align: center;
    background-color: rgb(100, 100, 100);
    color: white;
    cursor: pointer;
    padding: 0px 5px;
}
.shake {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes shake {
    0% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(0, -1px);
    }
    20% {
        transform: translate(0, -2px);
    }
    30% {
        transform: translate(0, 3px);
    }
    40% {
        transform: translate(0, -4px);
    }
    50% {
        transform: translate(0, 5px);
    }
    60% {
        transform: translate(0, -6px);
    }
    70% {
        transform: translate(0, 7px);
    }
    80% {
        transform: translate(0, -8px);
    }
    90% {
        transform: translate(0, 9px);
    }
    100% {
        transform: translate(0, 0);
    }
}

.toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 45px;
    text-align: center;
    gap: 15px;
    position: absolute;
    top: 1em;
    left: 50%; /* Position X halfway in */
    transform: translate(-50%, 0%); /* Move it halfway back(x,y) */
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.modal.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    height: 50vh;
    gap: 15px;
    position: absolute;
    top: 50%;
    left: 50%; /* Position X halfway in */
    transform: translate(-50%, -50%); /* Move it halfway back(x,y) */
    z-index: 1000;
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 15px;
}
.modalContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.modalContent > .close-btn {
    background-color: transparent !important;
    text-align: right;
    width: 100%;
    cursor: pointer;
    color: black;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
}

.modalFooter > .btn {
    border: 1px solid black;
}
