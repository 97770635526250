@font-face {
    font-family: 'unifont';
    src: url('../../../public/fonts/icomoon.ttf');
}
.media-controls button{
    font-family: 'unifont';
}
.file button{
    font-family: 'unifont';
}
.media-controls button{
    width:32px;
}

.player{
    bottom: 0;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
    width: 100%;
    flex-direction: row;
    text-align: center;
    background: white;
    padding-top: 15px;
    height: 145px;
    position: sticky;
}
.player__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    order: 2;
}

.player__container input{
    width: 60%;
    margin:auto;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    order: 3;
    padding-bottom: 35px;
    padding-top: 15px;
}
.player__container input::-webkit-slider-runnable-track {
    background: #053a5f;
    height: 2px;
  }
  
  /******** Firefox ********/
  .player__container input::-moz-range-track {
    background: #053a5f;
    height: 2px;
  }
  .player__container input::-webkit-slider-thumb {
      margin-top: -6px;
      -webkit-appearance: none;
      appearance: none;
      border: black 1px solid;
      outline: black 1px solid;
      height: 14px;
      width: 14px;
      border-radius: 14px;
      background: white;
  }
  .player__container input::-moz-range-thumb {
    margin-top: -6px;
    -webkit-appearance: none;
    appearance: none;
    border: black 1px solid;
    outline: black 1px solid;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    background: white;
}
.player__container .song-time{
    text-align: center;
    margin: auto;
    order: 2;
    width: 40px;
}
.player__container span{
    order: 1;
}

.song-details{
    width: 20%;
    height: 100%;
    order: 1;
    display: flex;
    flex-direction: row;
}
.song-details > img{
    height: 80%;
}
.song-details > span{
    margin: auto;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #053a5f;
    text-align: left;
}

.beatstore__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.song-container{
    height: auto;
    order: 1;
}
.beatstore__songs{
    height: 100%;
}

.beatstore__song{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 15px;
    padding: 10px;
    height: fit-content;

}

.beatstore__song > img {
    height: 150px;
}
.beatstore__song__info{
    width: calc(100% - 150px - 100px);
    margin-left: 20px;
}
@supports (-webkit-overflow-scrolling: touch) {
   button{
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    margin: 0em;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
   }
  }